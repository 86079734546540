import { Component, OnInit, Inject, AfterViewInit, ElementRef} from '@angular/core';
import { IncomeStatementService } from 'src/app/services/IncomeStatement/IncomeStatement.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { BalanceIncomeService } from 'src/app/services/sockets/balance-income/balance-income.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SocketioService } from 'src/app/services/socketio.service';
import { WorkshopService } from 'src/app/services/workshop/workshop.service';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-income-statement',
  templateUrl: './income-statement.component.html',
  styleUrls: ['./income-statement.component.scss']
})
export class IncomeStatementComponent implements OnInit, AfterViewInit{
  destroy$: Subject<boolean> = new Subject<boolean>();
  
  arrColumnas=[];
  myCall = false;
  jsonLabels = [];
  jsonCantidades = [];
  mes: number;
  deleteMes: number;
  blnShowAlert = false;
  canEdit = true;
  netIncome = 0;
  intentos = 0;
  /*áreas del board*/
  totalSales: number = 0;
  costGoodSold: number = 0;
  factotyOverheads: number = 0;
  selling: number = 0;
  interest: number = 0;
  taxes: number = 0;
  userId: number;
  isfacilitator=false;
  month: number = 0;
  isInCurrentStatus=false;
  blnSizeMin = false;
  elemento : any;
  newWidth: string;
  newHeight: string;
  constructor(private incomeService: IncomeStatementService, private spinner: NgxSpinnerService,
              private socketService: SocketioService,
              public languageService: LanguageService,
              public matDialogRef: MatDialogRef<IncomeStatementComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { teamId: any, isInCurrentStatus:boolean, languageId: any, userId: number, currentMonth: any, isfacilitador: any, cycles: any, workshopId: any, totSales: any, canEdit: boolean, control: boolean, arrTextLabels: any, userName: string},
              private balanceIncomeService: BalanceIncomeService,
              private elementRef: ElementRef,
              private workshopService: WorkshopService) { }

          /*    providers: [ { provide: MatDialog, useValue: dialogMock },
                {provide : MatDialogRef, useValue : dialogRefMock} 
          ]*/           
   ngOnDestroy(): void {
    this.balanceIncomeService.updateSize(1,this.userId,this.newWidth+','+this.newHeight).subscribe(res =>{
      if(res.intResponse == 200){
        console.log(this.newWidth+this.newHeight);
        this.destroy$.next(true);
        // Now let's also unsubscribe from the subject itself:
        this.destroy$.unsubscribe();
        this.balanceIncomeService.closeListener();
      }
    });
    
  }
  ngOnInit(): void {
    this.matDialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
          this.onCancel();
      }
    });

    this.spinner.show();
    this.fnLabels();
    console.log("team",this.data.teamId);
    console.log("mes ",this.data.currentMonth);
    console.log("Cycles ",this.data.cycles , this.data.workshopId);
    console.log("totSales ",this.data.totSales);
    this.totalSales = this.data.totSales;
    this.canEdit = this.data.canEdit;
    this.userId = this.data.userId;
    this.isInCurrentStatus = this.data.isInCurrentStatus;
    this.isfacilitator = this.data.isfacilitador;
    this.month = this.data.currentMonth;
    console.log("mes:",this.month," isfacilitator:",this.isfacilitator);
    //this.data.cycles=6;
    //this.data.currentMonth = 2;
    this.mes = this.data.currentMonth - 1;
    this.fnCantidadesBoard();

    
    this.workshopService.setLogFac(this.data.teamId,this.data.userName,'IS', 'Open', this.month).subscribe(res =>{ }) //Log for fac

    this.incomeService.getCountCantidades(this.data.teamId).subscribe(async response => {
      console.log("mio",response.data.total); 
      if(response.data.total == 0){
        console.log("ciclos, no habia registro");
        this.fnLlenado();
      }
      else{
        if(response.data.total == this.data.cycles){
          console.log("ciclos igual");
          this.fnObtenerDatos();
        } else{
            console.log("ciclos cambiaron");
            if(response.data.total>this.data.cycles){
              this.deleteMes = response.data.total - this.data.cycles;
              console.log("total",this.deleteMes)
              // this.fnDelete();
              this.fnLlenado();
            }
            else{
              console.log("here")
              this.fnLlenado();
            }
           
        }
      }
    });
   this.initSocket();
  }
  ngAfterViewInit() {
    // Acciones después de que la vista se ha inicializado
    console.log(this.matDialogRef);
    this.elemento = document.getElementById(this.matDialogRef.id);
    console.log(this.elemento);
    const observer = new MutationObserver((mutationsList, observer) => {
      // Recorre las mutaciones en busca de cambios en las dimensiones
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'attributes' && (mutation.attributeName === 'style' || mutation.attributeName === 'class')) {
          // Aquí puedes realizar acciones en respuesta a cambios en el estilo o clases del elemento
          // Por ejemplo, puedes verificar si el ancho o alto cambió y actuar en consecuencia
          this.newHeight = window.getComputedStyle(this.elemento).getPropertyValue('height');
          this.newWidth = window.getComputedStyle(this.elemento).getPropertyValue('width');
          // console.log(`Nuevo ancho: ${newWidth}px, Nuevo alto: ${newHeight}px`);
        }
      });
    });
    const observerOptions = {
      attributes: true,
      attributeFilter: ['style', 'class'],
      subtree: true,
    };
    
    // Registra el elemento de destino y comienza a observar
    observer.observe(this.elemento, observerOptions);
  }

  initSocket(){
    let init = false;
    this.balanceIncomeService.setupSocketConnection();
    this.socketService._coin
    .pipe(takeUntil(this.destroy$))
    .subscribe(response => {
      if (response.status == "pieceDisabled") {
        if(response.data.userId != this.userId){
          this.canEdit = false;
        }
      }
      else if (response.status == "controlDisabled") {
        if(response.data.userId != this.userId){
          this.canEdit = false;
        }
      }
    });
    this.balanceIncomeService._income
    .pipe(takeUntil(this.destroy$)).subscribe(response => {
      if(response){
        if(response.status == 'updateInputIncomeServer'){
          const data = this.arrColumnas[response.data.i];
          if(data){
            this.arrColumnas[response.data.i][response.data.id] = response.data.value
          }
        } else if(response.status == 'showToastResultNetIncomeServer' && init){
          Swal.mixin({
            // target: "#alert",
            customClass: {
              container: 'position-absolute'
            },
            toast: true,
            timerProgressBar: true,
            showConfirmButton: false,
            timer: 3000,
            position: 'center',
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          }).fire({
            html: response.data.txt
          });
          if(!this.myCall){
            this.matDialogRef.close({
              netIncome: response.data.resultado5,
              createNetIncome: false
            });
          }
        }
      }
    }); init = true;
  }

  onCancel(): void {
    this.workshopService.setLogFac(this.data.teamId,this.data.userName,'IS', 'Close', this.month).subscribe(res =>{ }) //Log for fac
    this.matDialogRef.close();
  }

  // fnDelete(){
  //   this.incomeService.deleteMonth(this.data.teamId,this.deleteMes ).subscribe(response => {
  //     console.log("delete mes: ",response);
  //     this.fnObtenerDatos();
  //   });
  // }
  
  fnLlenado(){
      this.incomeService.newRegister(this.data.workshopId, this.data.teamId, this.data.cycles).subscribe(response => {
        console.log("newRecord: ",response);
        this.fnObtenerDatos();
      });
  }

  fnObtenerDatos(){
    this.incomeService.getCantidadesLlenado(this.data.teamId).subscribe(async (response) => {
      console.log(response);
      this.arrColumnas = response['data'];
      this.spinner.hide();
      let responseTemp = await this.incomeService.getCountCantidadesTemp(this.data.teamId, this.data.currentMonth).toPromise();
      const id = this.arrColumnas[this.data.currentMonth-1].IncomeStatementSaveID;
      this.arrColumnas[this.data.currentMonth-1] = responseTemp.data ? responseTemp.data:this.arrColumnas[this.data.currentMonth-1];
      this.arrColumnas[this.data.currentMonth-1].IncomeStatementSaveID = id;
    });
  }

  /*obtener etiquetas de lenguaje*/
  fnLabels(){
    this.incomeService.getLabelsByLanguageId(this.data.languageId).subscribe((response) => {
      this.jsonLabels = response['data'];
      console.log("Labels: ",this.jsonLabels );
      //this.spinner.hide();
    });
  }

  fnObtenerLabel(id){
    let valor = this.jsonLabels.filter(x=>x.LabelNumber === id)
    let label = valor[0].Label;
    return label;
  }

  /*obtencion de datos del board*/
  fnCantidadesBoard(){
    this.incomeService.getCantidadesBoard(this.data.teamId).subscribe((response) => {
      this.jsonCantidades = response['data'];
      console.log("Cantidades: ",this.jsonCantidades);
      let costGoodSold = this.jsonCantidades.filter(x=>x.AreaDrag === 31);
      costGoodSold.forEach(x =>{
        if(x.tipo == 8){
          this.costGoodSold += (x.cantidad) *2 ;
        } else {
          this.costGoodSold += (x.cantidad);
        }
      });
  
      let factotyOverheads = this.jsonCantidades.filter(x=>x.AreaDrag === 20 || x.AreaDrag === 21);
      factotyOverheads.forEach(x =>{
        this.factotyOverheads = (this.factotyOverheads) + (x.cantidad);
      });
     
      let selling = this.jsonCantidades.filter(x=>x.AreaDrag === 22 || x.AreaDrag === 23 || x.AreaDrag === 24|| x.AreaDrag === 25|| x.AreaDrag === 26|| x.AreaDrag === 27|| x.AreaDrag === 28);
      selling.forEach(x =>{
        this.selling = (this.selling) + (x.cantidad);
      });
  
      let interest = this.jsonCantidades.filter(x=>x.AreaDrag === 29);
      interest.forEach(x =>{
        this.interest = x.cantidad;
      });
    
      let  taxes = this.jsonCantidades.filter(x=>x.AreaDrag === 36);
      taxes.forEach(x =>{
        this.taxes = x.cantidad;
      });
    
    });
  }

  validateKey(nextInput){
    document.getElementById(nextInput).focus();
  }

  

  fnValidateInput(valueInp){
    //console.log(valueInp);
    let varStrId= "input1"+valueInp;
    const valueEdited = (document.getElementById(varStrId) as HTMLInputElement);
  }

  /*calcular suma*/
  fnResultado(uno, dos, tres){
    console.log("val1:" + uno + "val2:" + dos);
    let varUno = "input"+uno;
    let varDos = "input"+dos;
    let varTres = "input"+tres;
    let val1,val2;

    console.log(varUno + varDos);

    if(parseInt((document.getElementById(varUno) as HTMLInputElement).value)){
      console.log("si hay valor");
      val1 = parseInt((document.getElementById(varUno) as HTMLInputElement).value)
    }else{
      val1 = 0;
    }

    if(parseInt((document.getElementById(varDos) as HTMLInputElement).value)){
      console.log("si hay valor");
      val2 = parseInt((document.getElementById(varDos) as HTMLInputElement).value)
    }else{
      val2 = 0;
    }
    var resultado = val1 - val2;
    (document.getElementById(varTres) as HTMLInputElement).value = resultado.toString();
    switch(varTres){
      case "input30":
        this.fnChangeInput(resultado, 'contribution', this.mes);
        break;
      case "input50":
        this.fnChangeInput(resultado, 'gross', this.mes);
        break;
      case "input70":
        this.fnChangeInput(resultado, 'operating', this.mes);
        break;
      case "input90":
        this.fnChangeInput(resultado, 'income', this.mes);
        break;
      case "input110":
        this.fnChangeInput(resultado, 'netIncome', this.mes);
        break;
        
    }

    // var resultado = (parseInt((document.getElementById(varUno) as HTMLInputElement).value)) - (parseInt((document.getElementById(varDos) as HTMLInputElement).value));
    // (document.getElementById(varTres) as HTMLInputElement).value = resultado.toString();
  }

  fnSave(){
  console.log("cantidades board: ", "totalSales", this.totalSales,"costGoodSold:",this.costGoodSold, "factotyOverheads:", this.factotyOverheads,"selling:",this.selling,"interest:",this.interest ,"taxes:",this.taxes)

  let totalsales,costGoodSold,factoryOverheads,selling,interest,taxes;
  var nu1 = parseInt((document.getElementById('input10') as HTMLInputElement).value);
  let nu2 = parseInt((document.getElementById('input100') as HTMLInputElement).value)
   console.log(nu1,nu2);
  if(parseInt((document.getElementById('input10') as HTMLInputElement).value)){
    totalsales = parseInt((document.getElementById('input10') as HTMLInputElement).value)
  }else{
    totalsales = 0;
  }

  if(parseInt((document.getElementById('input20') as HTMLInputElement).value)){
    costGoodSold = parseInt((document.getElementById('input20') as HTMLInputElement).value)
  }else{
    costGoodSold = 0;
  }

  if(parseInt((document.getElementById('input40') as HTMLInputElement).value)){
    factoryOverheads = parseInt((document.getElementById('input40') as HTMLInputElement).value)
  }else{
    factoryOverheads = 0;
  }

  if(parseInt((document.getElementById('input60') as HTMLInputElement).value)){
    selling = parseInt((document.getElementById('input60') as HTMLInputElement).value)
  }else{
    selling = 0;
  }

  if(parseInt((document.getElementById('input80') as HTMLInputElement).value)){
    interest = parseInt((document.getElementById('input80') as HTMLInputElement).value)
  }else{
    interest = 0;
  }

  if(parseInt((document.getElementById('input100') as HTMLInputElement).value)){
    taxes = parseInt((document.getElementById('input100') as HTMLInputElement).value)
  }else{
    taxes = 0;
  }


  console.log("input",(parseInt((document.getElementById('input10') as HTMLInputElement).value)),(parseInt((document.getElementById('input20') as HTMLInputElement).value)),
              (parseInt((document.getElementById('input30') as HTMLInputElement).value)),(parseInt((document.getElementById('input40') as HTMLInputElement).value)),
              (parseInt((document.getElementById('input50') as HTMLInputElement).value)),(parseInt((document.getElementById('input60') as HTMLInputElement).value)),
              (parseInt((document.getElementById('input70') as HTMLInputElement).value)),(parseInt((document.getElementById('input80') as HTMLInputElement).value)),
              (parseInt((document.getElementById('input90') as HTMLInputElement).value)),(parseInt((document.getElementById('input100') as HTMLInputElement).value)),
              (parseInt((document.getElementById('input110') as HTMLInputElement).value))); 

  let resultado = totalsales - costGoodSold;
  let resultado2 = resultado - factoryOverheads;
  let resultado3 = resultado2 - selling;
  let resultado4 = resultado3 - interest;
  let resultado5 = resultado4 - taxes;
  console.log(resultado, resultado2, resultado3, resultado4,resultado5);

  if(this.data.control){
    console.log("no tiene el control");
    return;
  }

   if((totalsales == this.totalSales) && 
      (costGoodSold == this.costGoodSold) &&
      (factoryOverheads == this.factotyOverheads) &&
      (selling == this.selling) &&
      (interest == this.interest) &&
      (taxes == this.taxes) &&
      (parseInt((document.getElementById('input30') as HTMLInputElement).value)== resultado) && 
      (parseInt((document.getElementById('input50') as HTMLInputElement).value)== resultado2)  && 
      (parseInt((document.getElementById('input70') as HTMLInputElement).value)== resultado3) &&
      (parseInt((document.getElementById('input90') as HTMLInputElement).value)== resultado4) &&
      (parseInt((document.getElementById('input110') as HTMLInputElement).value)== resultado5)){
        console.log("si es igual")
    
        this.incomeService.updateCantidades(
          this.data.workshopId, 
          this.data.teamId,
          this.data.currentMonth, 
          this.totalSales.toString(),
          this.costGoodSold.toString(),
          resultado.toString(),
          this.factotyOverheads.toString(),
          resultado2.toString(),
          this.selling.toString(),
          resultado3.toString(),
          this.interest.toString(),
          resultado4.toString(),
          this.taxes.toString(),
          resultado5.toString()).subscribe((response) => {
          console.log("updateRecord: ",response);
        });


        console.log(this.data.workshopId, this.data.teamId, this.data.currentMonth);
        this.incomeService.updateMonth(this.data.workshopId, this.data.teamId, this.data.currentMonth).subscribe((response) => {
          console.log("update month: ",response);
        });
        this.blnShowAlert = true;
        this.netIncome = resultado5;
        let text = "";
        if(this.netIncome > 0){
          text = `${this.getErrorLabel('1089')} ${this.netIncome}!`;
        }else{
          if(this.netIncome == 0){
            text = `${this.getErrorLabel('1098')}!`;
          }else{
            text = this.getErrorLabel('1090');

          }
        }
        this.balanceIncomeService.showToastResultNetIncome(text, 'room'+this.data.teamId, resultado5);
        this.myCall = true;
        this.matDialogRef.close({
          netIncome: resultado5,
          createNetIncome: true
        });
   }
   else{
    console.log("NO es igual");
    (document.getElementById('input110') as HTMLInputElement).value = '0';
    (document.getElementById('input30') as HTMLInputElement).value = '0';
    (document.getElementById('input50') as HTMLInputElement).value = '0';
    (document.getElementById('input70') as HTMLInputElement).value = '0';
    (document.getElementById('input90') as HTMLInputElement).value = '0';
    this.intentos += 1;
    
   }
  }

  fnChangeInput(value, id, i){
    this.balanceIncomeService.updateInputIncomeStatement(value, id, 'room'+this.data.teamId,i, this.data.workshopId,this.data.teamId, this.data.currentMonth);
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this.data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }

  getErrorLabel(labelNumber: string, code: string | null = null): string {
    const tmp = this.data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    let msg = ''
    if (tmp) {
      const LabelBase = tmp.LabelBase;
      let errorCode = code;
      if (!code) {
        const array = LabelBase.split(':');
        errorCode = array.length > 0 ? array[0] : '';
      }
      const className = environment.isProd ? 'lightgray' : 'gray';
      msg = `<div><span style="color:${className}; font-size: 12px">${errorCode}: </span>${tmp.Label}</div>`;
    }
    return msg;
  }

  validateInputNumber(event: KeyboardEvent) {
    const pattern = /^(-{1}?(?:([0-9]{0,10}))|([0-9]{1})?(?:([0-9]{0,9})))?(?:\.([0-9]{0,3}))?$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {    
        event.preventDefault();
    }
  }

  moveFocusInput(event: any){
    if(event){
      let strElementId = event.srcElement.id;
      let number = strElementId.split('input')[1];
      number = parseInt(number);
      number = 
        event.which == 38 && number >= 20 ? number - 10 : 
          event.which == 40 && number <= 110 ? number + 10 : 
            number;
      document.getElementById("input"+number).focus();
    }
  }
  fnResize(blnSizeMin){
    console.log("resizing");
    if(blnSizeMin)
    {
      blnSizeMin = false; 
      this.matDialogRef.updateSize("915px","600px");
  
    }
    else
    {
      blnSizeMin= true;
      this.matDialogRef.updateSize("330px","400px");
    }
    console.log("after resize");
  }
}
