<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
<label class="style3 calibriFont FinTitle" id="example-radio-group-label">{{getSingleTextLabel('5019')}}</label><br><br>
<div style="display: flex;justify-content: space-between;">
  <mat-radio-group [(ngModel)]="optionSelected" (change)='changeOption($event)'>
    <mat-radio-button class="style2a calibriFont" *ngFor="let cycle of auxArray" [value]="'month'+cycle">{{getSingleTextLabel('5013')}} {{cycle}} &nbsp;&nbsp;</mat-radio-button>
    <mat-radio-button class="style2a calibriFont" value="ratio">{{getSingleTextLabel('7009')}}</mat-radio-button>
  </mat-radio-group>
  <button  (click)="changeOption($event)" *ngIf="optionSelected != 'ratio'" class="btn btn2 btn-primary">
            Refresh
        </button>
</div>

<ng-container *ngIf="optionSelected == 'ratio'">
  <app-result-ratios></app-result-ratios>
</ng-container>


<table class="table" *ngIf="blnShowtable && optionSelected != 'ratio'">
    <thead>
      <tr>
        <th scope="col" style="color: transparent;background-color: transparent;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
        <ng-container *ngFor="let jsnTeam of lstTeams; let i = index;">
            <th scope="col" [ngStyle]="{'background-color': jsnTeam.Color, 'color': jsnTeam.Color === '#ffe998' || jsnTeam.Color === '#84eeeb' || jsnTeam.Color === '#bec3ff' || jsnTeam.Color === undefined || jsnTeam.Color  == '#e4e4ec' || jsnTeam.Color  == '#7faeff' ? 'black' : 'white'}" class="text-center style4SinBackground calibriFont">{{jsnTeam.Avatar}}</th>
            
         </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="elements1erImprovement.length > 0">
        <th scope="row" style="font-weight: 500;" class="style5cItal border calibriFont">{{getSingleTextLabel('5006')}} 1:</th>
        <!-- <td *ngIf="blnFacilitador" scope="col" class="style5cItal border calibriFont"></td> -->
        <ng-container *ngFor="let jsnImp of elements1erImprovement;let i = index;">
          <td scope="col" class="style5cItal border calibriFont" id="imp1{{i}}">{{jsnImp.improvement}}</td>
       </ng-container>
      </tr>
      <tr *ngIf="elements2doImprovement.length > 0 && blnShow2Improvement">
        <th scope="row" style="font-weight: 500;" class="style5cItal border calibriFont">{{getSingleTextLabel('5006')}} 2:</th>
        <!-- <td *ngIf="blnFacilitador" scope="col" class="style5cItal border calibriFont"></td> -->
        <ng-container *ngFor="let jsnImp of elements2doImprovement;let i = index;">
          <td scope="col" class="style5cItal border calibriFont" id="imp2{{i}}" >{{jsnImp.improvement}}</td>
          
       </ng-container>
      </tr>
      <tr>
        <th scope="row" style="font-weight: 500;" class="style5b border calibriFont">{{getSingleTextLabel('14')}}:</th>
        <ng-container *ngFor="let jsndata of lstTabletotalsales;let i = index;">
          <td scope="col" id="sales{{i}}" class="style5b border calibriFont" style="cursor: pointer;" (click)="fnClickSelect('sales', i)" >{{jsndata.totalsales}}</td>
          
       </ng-container>
      </tr>
      <tr>
        <th scope="row" style="font-weight: 500;" class="style5b border calibriFont">{{getSingleTextLabel('5001')}}:</th>
        <ng-container *ngFor="let jsndata of lstTabletotalsales;let i = index;">
          <td scope="col"  id="sold{{i}}" class="style5b border calibriFont" style="cursor: pointer;" (click)="fnClickSelect('sold', i)">
          {{ blnFacilitador || monthSelected !== currentMonth
            ? jsndata.totalquantity : monthSelected === currentMonth && (intOpenMarket == 2 || status <= 0) 
              ? jsndata.totalquantity : 0 }}
          </td>
          
       </ng-container>
      </tr>
      <tr>
        <th scope="row" style="font-weight: 500;" class="style5b border calibriFont" >{{getSingleTextLabel('5002')}}:</th>
        <ng-container *ngFor="let jsndata of lstTabletotalsales;let i = index;">
          <td scope="col" id="avgr{{i}}" class="style5c border calibriFont" style="cursor: pointer;" (click)="fnClickSelect('avgr', i)">{{jsndata.averageprice}}</td>
          
       </ng-container>
      </tr>
      <tr>
        <th scope="row" style="font-weight: 500;" class="style5b border calibriFont">{{getSingleTextLabel('30')}}:</th>
        <ng-container *ngFor="let jsndata of lstTabletotalsales;let i = index;">
          <td scope="col" id="advert{{i}}" class="style5b border calibriFont" style="cursor: pointer;" (click)="fnClickSelect('advert', i)">{{ jsndata.advertising ? jsndata.advertising : '0' }} </td>
          
       </ng-container>
      </tr>
      <tr>
        <th scope="row" class="style5bBold border calibriFont">{{getSingleTextLabel('42')}}:</th>
        <ng-container *ngFor="let jsndata of lstTabletotalsales;let i = index;">
          <td scope="col" id="netInc{{i}}" class="style5bBold border calibriFont" (click)="fnClickSelect('netInc', i)" style="cursor: pointer;"  [style.color]="jsndata.color_netIncome">{{jsndata.netIncome}}</td>
          
       </ng-container>
      </tr>
      <tr>
        <th scope="row" class="style5bBold border calibriFont">{{getSingleTextLabel('74')}}:</th>
        <ng-container *ngFor="let jsndata of lstTabletotalsales;let i = index;">
          <td scope="col" id="retain{{i}}" class="style5bBold border calibriFont" (click)="fnClickSelect('retain', i)" style="cursor: pointer;"  [style.color]="jsndata.color_retained">{{jsndata.retained}}</td>
          
       </ng-container>
      </tr>
      <tr *ngIf="optionSelected == 'month3'">
        <th scope="row">{{getSingleTextLabel('5007')}}:</th>
        <ng-container *ngFor="let item of lstSelects;let i = index;">
          <td scope="col">
            <mat-form-field class="w-100" appearance="fill">
              <mat-label>{{getSingleTextLabel('137')}}</mat-label>
              <mat-select [disabled]="!blnFacilitador" (selectionChange)="changeSelect()" [(ngModel)]="item.text" >
                <mat-option value="opex">{{getSingleTextLabel('7168')}}</mat-option>
                <mat-option value="capex">{{getSingleTextLabel('7')}}</mat-option>
                <mat-option value="both">{{getSingleTextLabel('5020')}}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          
       </ng-container>
      </tr>
      <tr *ngFor="let item of lstTextArea; let i=index">
        <th scope="row" class="style5b">
          <mat-form-field class="w-100" appearance="fill">
            <textarea class="textAreaFormField style5b calibriFont" [disabled]="!blnFacilitador" (change)="changeTextArea()"
              [id]="item[0].idtextAreaReports" name="notes" matInput rows="3"
              [(ngModel)]="item[0].text">{{item[0].text}}</textarea>
          </mat-form-field>
        </th>
        <ng-container *ngFor="let textArea of item; let i=index">
          <ng-container *ngIf="i != 0">
            <td>
              <mat-form-field appearance="fill">
                <textarea class="textAreaFormField" style5b calibriFont [disabled]="!blnFacilitador" (change)="changeTextArea()"
                  [id]="textArea.idtextAreaReports" name="notes" matInput rows="3" cols="17"
                  [(ngModel)]="textArea.text">{{textArea.text}}</textarea>
              </mat-form-field>
            </td>
          </ng-container>
        </ng-container>
      </tr>
    </tbody>
  </table>

  <!-- <div class="container" *ngIf="blnShowtable && optionSelected != 'ratio'">
    <div class="row" *ngIf="optionSelected == 'month3'">
      <div class="col-2 w-100">
        <p>OpEx or CapEx</p>
      </div>
      <div class="col-2 w-100" *ngFor="let item of lstSelects">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Select</mat-label>
          <mat-select [disabled]="!blnFacilitador" (selectionChange)="changeSelect()" [(ngModel)]="item.text" >
            <mat-option value="opex">OpEx</mat-option>
            <mat-option value="capex">CapEx</mat-option>
            <mat-option value="both">Both</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-2" *ngFor="let textArea of lstTextArea">
        <mat-form-field  class="w-100" appearance="fill">
          <textarea class="textAreaFormField" [disabled]="!blnFacilitador" (change)="changeTextArea()" [id]="textArea.idtextAreaReports1" name="notes" rows="10" matInput  cols="17" rows="3" [(ngModel)]="textArea.text" >{{textArea.text}}</textarea>
        </mat-form-field>
      </div>
    </div>
  </div> -->

</div>